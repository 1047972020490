import { render, staticRenderFns } from "./gameInfoPop.vue?vue&type=template&id=0ad65a0d&scoped=true"
import script from "./gameInfoPop.vue?vue&type=script&lang=js"
export * from "./gameInfoPop.vue?vue&type=script&lang=js"
import style0 from "./gameInfoPop.vue?vue&type=style&index=0&id=0ad65a0d&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_cache-loader@4.1.0_webpack@4.47.0__css-load_7907ed7515d572276d45114f834f04a7/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ad65a0d",
  null
  
)

export default component.exports